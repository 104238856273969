

export const BOTTOM_BAR_HEIGHT = '64px';
export const COLOR = {
    LIGHT: '#ececf9',
    DARK: '#c5c5ed',
    ACCENT: '#18184e',
    ACCENT_LIGHT: '#9e9ee0',
    RED: '#b30000',
    GREEN: '#008000',
    WHITE: '#f5f5f5'
}
