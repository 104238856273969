import styled from "styled-components"
import { COLOR } from "./constants";


interface Props {
    display: boolean,
    onClose: () => void
    children?: JSX.Element | JSX.Element[] | string
}

export const Modal = ({ display, onClose, children }: Props) => {
    if (!display) return <></>;

    return (
        <>
            <Backdrop />
            <Container className={'shadowed'}>
                <CloseButton onClick={onClose} className={'shadowed'}>
                    <Modal.CloseX />
                </CloseButton>

                { children }
            </Container>
        </>
    )
}

Modal.CloseX = (props: { color?: string }) => (
    <svg width={32} height={32}>
        <polyline points="3,3 7,3 16,12 25,3 29,3 29,7 20,16 29,25 29,29 25,29 16,20 7,29 3,29 3,25 12,16 3,7" fill={props.color ?? COLOR.LIGHT} />
    </svg>
);

const Backdrop = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    backdrop-filter: blur(4px) brightness(.7);
`

const Container = styled.div`
    position: fixed;
    top: 24px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    z-index: 20;
    border: 2px solid ${COLOR.ACCENT};
    background-color: ${COLOR.LIGHT};
    padding: 24px;
`

const CloseButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${COLOR.RED};
    border: none;
    padding: 0;
    margin: 0;
    height: 32px;
    width: 32px;

    &:active, &:focus, &:hover {
        filter: brightness(2);
        cursor: pointer;
    }
`
