import styled from "styled-components"
import { COLOR } from "./constants"
import { DieNumber } from "./types"


interface Props {
    value?: DieNumber
}

export const Die = ({ value }: Props) => {
    if (!value) return <></>

    return (
        <DieFrame>
            {makeDots(value)}
        </DieFrame>
    )
}

const DieFrame = styled.div`
    border: 1px solid black;
    background-color: ${COLOR.WHITE};
    height: 32px;
    width: 32px;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
`
const DOT_SIZE = 3;
const SIDE_LENGTH = 32;

const makeDots = (value: DieNumber) => {
    if (value === 1) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={SIDE_LENGTH / 2} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
        </svg>)
    }
    else if (value === 2) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={1 / 3 * SIDE_LENGTH} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
            <circle cx={2 / 3 * SIDE_LENGTH} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
        </svg>)
    }
    else if (value === 3) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={1 / 4 * SIDE_LENGTH} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
            <circle cx={2 / 4 * SIDE_LENGTH} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
            <circle cx={3 / 4 * SIDE_LENGTH} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
        </svg>)
    }
    else if (value === 4) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={1 / 3 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 3 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={1 / 3 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 3 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
        </svg>)
    }
    else if (value === 5) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={1 / 3 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 3 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={1 / 3 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 3 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={SIDE_LENGTH / 2} cy={SIDE_LENGTH / 2} r={DOT_SIZE} />
        </svg>)
    }
    else if (value === 6) {
        return (<svg width={SIDE_LENGTH} height={SIDE_LENGTH}>
            <circle cx={1 / 4 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 4 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={3 / 4 * SIDE_LENGTH} cy={1 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={1 / 4 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={2 / 4 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
            <circle cx={3 / 4 * SIDE_LENGTH} cy={2 / 3 * SIDE_LENGTH} r={DOT_SIZE} />
        </svg>)
    }
    else { throw Error(`Unknown die value: ${value}`) }
}