import styled from "styled-components"
import { COLOR } from "./constants"
import { DieNumber } from "./types"


interface Data {
    attackers: number
    defenders: number
    isSimulating?: boolean
    result?: {
        attackersDead: number
        defendersDead: number
        rolls: {
            attackers: DieNumber[][]
            defenders: DieNumber[][]
        }
    }
}

export const ResultsView = (data: Data) => {
    if (data.isSimulating) {
        return <div>...</div>
    }
    if (!data.result) {
        return (
            <div>
                <FightTitle>Fight!</FightTitle>
                <FightText>
                    Set values for committed attackers and defenders. A series of combats is simulated according to <a href="https://googlethatforyou.com/?q=Risk+board+game" target={'_blank'} rel={'noreferrer'}>Risk <AwayArrow /></a> rules, until an attack is victorious or all attackers die.
                </FightText>
            </div>
        )
    }

    const attackersWin = data.attackers > data.result.attackersDead
    return (
        <div>
            { attackersWin ? <AttackersWin>Attack is victorious</AttackersWin> : <DefendersWin>Defence holds</DefendersWin> }
            <Casualties>
                <Casualty>
                    <h5><Icon />Attackers</h5>
                    <p><Icon>&#9829;</Icon>{data.attackers - data.result.attackersDead} survive</p>
                    <p><Icon>&#128128;</Icon>{data.result.attackersDead} die</p>
                </Casualty>
                <Casualty>
                    <h5><Icon />Defenders</h5>
                    <p><Icon>&#9829;</Icon>{data.defenders - data.result.defendersDead} survive</p>
                    <p><Icon>&#128128;</Icon>{data.result.defendersDead} die</p>
                </Casualty>
            </Casualties>
        </div>
    )
}

export const AwayArrow = () => (
    <svg width={8} height={8}>
        <polyline points="1,8 0,7 5,2 1,0 8,0 8,7 6,3" />
    </svg>
);

const FightTitle = styled.h3`
    margin-top: 0;
    text-align: center;
    letter-spacing: 1px;
`
const FightText = styled.p`
    & a {
        text-decoration: none;
    }
`

const AttackersWin = styled.h2`
    margin-top: 0;
    color: ${COLOR.GREEN};
    text-align: center;
    letter-spacing: 1px;
`

const DefendersWin = styled.h2`
    margin-top: 0;
    color: ${COLOR.RED};
    text-align: center;
    letter-spacing: 1px;
`

const Casualties = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
`

const Casualty = styled.div`
    & h4, h5 {
        margin: 0;
        text-transform: uppercase;
    }
`

const Icon = styled.span`
    width: 24px;
    display: inline-block;
    text-align: center;
`
