import React, { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, NavLink, Route, RouterProvider, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BOTTOM_BAR_HEIGHT, COLOR } from './constants';
import { Simulate } from './pages/Simulate';
import { Stats } from './pages/Stats';

const Container = styled.div`
  height: calc(100vh - ${BOTTOM_BAR_HEIGHT});
  position: fixed;
  top: 0;
  width: 100%;
`;

const BottomBar = styled.nav`
  background-color: ${COLOR.DARK};
  height: ${BOTTOM_BAR_HEIGHT};
  width: 100%;
  position: fixed;
  bottom: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
`;

const Content = styled.main`
  background-color: ${COLOR.LIGHT};
  height: 100%;
`;

const BottomLink = styled.div`
  width: 96px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const makeNavLinkStyle = ({ isActive }: { isActive: boolean }) => ({
  backgroundColor: isActive ? COLOR.ACCENT : COLOR.DARK,
  textDecoration: 'none',
  borderRadius: '30px 30px 0 0',
  border: isActive ? 'none' : `1px solid ${COLOR.ACCENT}`
});

const usingContent = (content?: JSX.Element) => (
  <Container>
    <Content>
      {content}
    </Content>
    <BottomBar>
      <NavLink to="/simulate" style={makeNavLinkStyle} className={'shadowed'}>
        <BottomLink><span>Combat</span></BottomLink>
      </NavLink>
      <NavLink to="/stats" style={makeNavLinkStyle} className={'shadowed'}>
        <BottomLink><span>Speculate</span></BottomLink>
      </NavLink>
    </BottomBar>
    <style>
      {`
      .active span {
        color: ${COLOR.LIGHT};
      }

      .shadowed {
        box-shadow: 0 0 4px 0 ${COLOR.ACCENT};
      }

      .shadowed-inset {
        box-shadow: 0 0 4px 0 ${COLOR.ACCENT} inset;
      }
      `}
    </style>
  </Container>
)

const GoToSimulate = () => {
  const nav = useNavigate();
  useEffect(() => {
    nav('/simulate');
  }, [nav]);

  return <></>
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="simulate" element={usingContent(<Simulate />)} />
      <Route path="stats" element={usingContent(<Stats />)} />
      <Route index element={usingContent(<GoToSimulate />)} />
    </Route>
  )
)

export const App = () => ( <RouterProvider router={router} /> );

