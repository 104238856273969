import { DieNumber } from "../types";

export const throwADie = () => {
    return (Math.floor(Math.random() * 6) + 1) as DieNumber;
}

export const throwDice = (count: number) => {
    const res: DieNumber[] = [];
    for (let i = 0; i < count; i++) {
        res.push(throwADie());
    }
    return res;
}
