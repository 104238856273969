import { DieNumber } from "../types";
import { throwDice } from "./die"


export interface SingleCombatInput {
    attackers: number
    defenders: number
}

export interface CommitInput extends SingleCombatInput {

}

const DESC = (a: number, b: number) => b - a;

export const simulateSingleCombat = async ({ attackers, defenders }: SingleCombatInput) => {
    const result = {
        attackersRoll: throwDice(attackers).sort(DESC),
        defendersRoll: throwDice(defenders).sort(DESC),
        attackersDead: 0,
        defendersDead: 0
    }

    for (let i = 0; i < Math.min(attackers, defenders); i++) {
        if (result.attackersRoll[i] > result.defendersRoll[i]) {
            result.defendersDead++;
        }
        else {
            result.attackersDead++;
        }
    }

    return result;
}

export const simulateCommit = async ({ attackers, defenders }: CommitInput) => {
    let attackersLeft = attackers;
    let defendersLeft = defenders;
    const rolls = { attackers: [] as DieNumber[][], defenders: [] as DieNumber[][] }
    const left = { attackers: [] as number[], defenders: [] as number[] }

    while (attackersLeft > 0 && defendersLeft > 0) {
        const attacking = Math.min(attackersLeft, 3);
        const defending = Math.min(defendersLeft, 2);
        const result = await simulateSingleCombat({ attackers: attacking, defenders: defending });

        attackersLeft -= result.attackersDead;
        defendersLeft -= result.defendersDead;

        rolls.attackers.push(result.attackersRoll);
        rolls.defenders.push(result.defendersRoll);
        left.attackers.push(attackersLeft);
        left.defenders.push(defendersLeft);
    }

    return {
        attackersDead: attackers - attackersLeft,
        defendersDead: defenders - defendersLeft,
        rolls,
        left
    }
}