import { useState } from "react"
import styled from "styled-components"
import { COLOR } from "./constants"


interface Props {
    value: number
    minValue?: number
    maxValue?: number
    onValueUpdate(value: number): void
}

export const NumberPicker = ({ value, maxValue, minValue, onValueUpdate }: Props) => {
    const [isTyping, setIsTyping] = useState(false);
    const min = minValue ?? 0;
    const max = maxValue;

    const tryUpdateValue = (newValue: number) => {
        if ([NaN, null, undefined].includes(newValue)) return;
        if (newValue < min) return;
        if (max !== undefined && newValue > max) return;
        onValueUpdate(newValue);
    }

    const increaseClick = () => {
        tryUpdateValue(value + 1);
    }

    const decreaseClick = () => {
        tryUpdateValue(value - 1);
    }

    const activateInput = () => {
        setIsTyping(true);
    }

    const deactivateInput = () => {
        setIsTyping(false);
    }


    return (
        <Container className={'shadowed'}>
            <Box onClick={() => increaseClick()}>
                <UpArrow />
            </Box>
            <Box onClick={activateInput} onBlur={deactivateInput}>
                { isTyping ?
                    (<input
                        type={'number'}
                        autoFocus
                        min={min}
                        max={max}
                        onKeyUp={(e) => { if (e.key === 'Enter') (e.target as HTMLInputElement).blur() }}
                        onBlur={e => tryUpdateValue(parseInt(e.target.value))}
                        style={{width: 32, height: 32}}
                    />) :
                    value}
            </Box>
            <Box onClick={() => decreaseClick()}>
                <DownArrow />
            </Box>
        </Container>
    )
}

const Container = styled.div`
    border: 1px solid gray;
    border-radius: 6px;
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
`

const UpArrow = () => (
    <svg height={8} width={8}>
        <polyline points="1,6 4,1 7,6" />
    </svg>
)
const DownArrow = () => (
    <svg height={8} width={8}>
        <polyline points="1,2 4,7 7,2" />
    </svg>
)

const Box = styled.div`
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
        background-color: ${COLOR.ACCENT_LIGHT};
    }
`