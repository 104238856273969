import { useEffect, useState } from "react"
import styled from "styled-components"
import { CombatAnimation } from "../CombatAnimation"
import { BOTTOM_BAR_HEIGHT, COLOR } from "../constants"
import { simulateCommit } from "../logic/risk"
import { Modal } from "../Modal"
import { NumberPicker } from "../NumberPicker"
import { ResultsView } from "../ResultView"
import { Toggle } from "../Toggle"
import { CombatResult, Commit } from "../types"

interface Data extends Commit {
    isSimulating?: boolean
    result?: CombatResult
}

export const Simulate = () => {
    const [data, setData] = useState<Data>({ attackers: 3, defenders: 2 });
    const [showAnimationModal, setShowAnimationModal] = useState(false);
    const [skipAnimation, setSkipAnimation] = useState(false);
    const setAttackers = (value: number) => setData(data => { return { ...data, attackers: value, result: undefined } })
    const setDefenders = (value: number) => setData(data => { return { ...data, defenders: value, result: undefined } })

    useEffect(() => {
        if (!data.isSimulating) return;

        (async () => {
            const result = await simulateCommit(data);
            setData(old => { return {...old, result, isSimulating: false }});
        })();
        // eslint-disable-next-line
    }, [data.isSimulating])

    const onCommit = () => {
        setData(data => { return { ...data, result: undefined, isSimulating: true }});
        if (!skipAnimation) setShowAnimationModal(true);
    }

    return (
        <div style={{height: '100%', display: 'flex', flexFlow: 'column nowrap'}}>
            <Title>Risk Combat Simulator</Title>
            <ResultsArea className={'shadowed-inset'}><ResultsView {...data}/></ResultsArea>
            <Bottomer>
                <Row>
                    <Toggle
                        checked={skipAnimation}
                        onValueChange={v => setSkipAnimation(v)}
                        label={"Skip animation"}
                    />
                    <Centered>
                        <NumberPickerLabel>Attackers</NumberPickerLabel>
                        <NumberPicker value={data.attackers} minValue={1} onValueUpdate={value => setAttackers(value)}></NumberPicker>
                    </Centered>
                    <Centered>
                        <NumberPickerLabel>Defenders</NumberPickerLabel>
                        <NumberPicker value={data.defenders} minValue={1} onValueUpdate={value => setDefenders(value)}></NumberPicker>
                    </Centered>
                </Row>
                <Row>
                    
                    <Button onClick={() => onCommit()} className={'shadowed'}>Commit</Button>
                </Row>
            </Bottomer>

            <Modal display={showAnimationModal} onClose={() => setShowAnimationModal(false)} >
                { data.result !== undefined ? <CombatAnimation {...data} result={data.result}></CombatAnimation> : <></> }
            </Modal>
        </div>
    )
}


const Title = styled.h1`
    text-align: center;
    letter-spacing: 3px;
    margin: 0;
    padding: .6em 0;
`

const Row = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
`

const Centered = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const Button = styled.button`
    background-color: ${COLOR.ACCENT};
    border: none;
    border-radius: 5px;
    padding: 16px 32px;
    margin: 2em 0;
    color: ${COLOR.LIGHT};

    &:active {
        background-color: ${COLOR.ACCENT_LIGHT};
    }
`
const ResultsArea = styled.div`
    height: 15em;
    border: 1px solid ${COLOR.ACCENT};
    border-radius: 1em;
    margin: 0 2em 2em;
    padding: 2em;
    box-sizing: border-box;
`

const NumberPickerLabel = styled.span`
    margin-bottom: 8px;
`

const Bottomer = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;

    padding-bottom: ${BOTTOM_BAR_HEIGHT};
`
