import anime from "animejs";
import { MouseEvent, RefObject, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { COLOR } from "./constants";


interface Props {
  label?: string | JSX.Element
  checked?: boolean
  onValueChange(newValue: boolean): void
}

export const Toggle = ({ label, checked, onValueChange }: Props) => {
  // Change to false to prevent additional clicks while loading
  const [active, setActive] = useState(true);
  const indicatorRef = useRef<HTMLDivElement>(null);
  checked = checked ?? false;

  const onClick = (e: MouseEvent) => {
    if (!active) return;
    setActive(false);

    anime({
      targets: indicatorRef.current,
      easing: 'easeInOutQuad',
      translateX: [
        { value: parseInt(INDICATOR_HEIGHT.slice(0, -2)) * (checked ? 0 : 0.5), duration: 100 }
      ],
      complete: () => {
        onValueChange(!checked);
        setActive(true);
      }
    });
  }


  return (
  <Container onClick={onClick}>
    <Label>{label}</Label>
    <ToggleButton {...{ checked, active, indicatorRef }} />
  </Container>)
}

const Label = styled.label`
  margin-bottom: 8px;
`

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const ToggleButton = ({ checked, active, indicatorRef }: { checked: boolean, active: boolean, indicatorRef?: RefObject<HTMLDivElement> }) => {

  return (
  <ButtonContainer {...{ active, checked }} className={'shadowed'}>
    <ButtonIndicator ref={indicatorRef} />
  </ButtonContainer>)
}

const INDICATOR_HEIGHT = '48px';
const CONTAINER_PADDING= '2px';

type ButtonProps = { active: boolean, checked: boolean }
const ButtonContainer = styled.button`
  padding: ${CONTAINER_PADDING};
  width: calc((${INDICATOR_HEIGHT} + ${CONTAINER_PADDING}) * 1.5);
  border: 1px solid black;
  border-radius: calc((${INDICATOR_HEIGHT} + ${CONTAINER_PADDING})/ 2);
  box-sizing: content-box;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background-color: ${COLOR.LIGHT};

  ${(props: ButtonProps) => props.checked && css`
  background-color: ${COLOR.ACCENT};
`}
`

const ButtonIndicator = styled.div`
  width: calc(${INDICATOR_HEIGHT} );
  height: calc(${INDICATOR_HEIGHT} );
  border: 1px solid black;
  border-radius: calc((${INDICATOR_HEIGHT} ) / 2);

  background-color: ${COLOR.DARK};
`
