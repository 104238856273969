import anime from "animejs"
import { useRef, useState } from "react"
import styled from "styled-components"
import { COLOR } from "./constants"
import { Die } from "./Die"
import { Modal } from "./Modal"
import { CombatResult, Commit } from "./types"

interface Props extends Commit {
    result: CombatResult
}

const Combatant = ({ num, color, lastWithX }: { num: number, color: string, lastWithX?: number }) => {
    if (num > 16) return <code style={{ color }}>Left: {num}</code>;

    const isAmongLastWithX = (k: number) => lastWithX !== undefined && k >= num - lastWithX;
    return (
        <div style={{ minHeight: '36px' }}>
            { [...Array(num).keys()].map(k => (!isAmongLastWithX(k) ?
                <CharacterIcon key={k} color={color} /> :
                <div style={{ width: '32px', height: '32px', display: 'inline-block' }} key={k} >
                    <Modal.CloseX color={COLOR.RED} />
                </div>
            )) }
        </div>
    )
}

const UPDATES_PER_SECOND = 100;
const SECONDS_PER_ROLL = 3;

const updatesPerRoll = UPDATES_PER_SECOND * SECONDS_PER_ROLL;
const msPerUpdate = 1000 * 1/(UPDATES_PER_SECOND);
const rollPerUpdate = 1 / updatesPerRoll;

export const CombatAnimation = (props: Props) => {
    const [rollProgress, setRollProgress] = useState(0);
    const rollId = Math.floor(rollProgress);
    const phase = rollProgress % 1;

    const attackersLeft = props.result.left.attackers[rollId];
    const defendersLeft = props.result.left.defenders[rollId];
    const attackerRoll = props.result.rolls.attackers[rollId];
    const defenderRoll = props.result.rolls.defenders[rollId];
    const attackersLeftBefore = rollId === 0 ? props.attackers :
        props.result.left.attackers[rollId - 1];
    const defendersLeftBefore = rollId === 0 ? props.defenders :
        props.result.left.defenders[rollId - 1];
    const isNextRound = rollId < props.result.rolls.attackers.length - 1;

    const [progressInterval, setProgressInterval] = useState<NodeJS.Timer>();
    if (isNextRound && !progressInterval) {
        setProgressInterval(setInterval(() => {
            setRollProgress(p => p + rollPerUpdate)
        }, msPerUpdate))
    }
    if (!isNextRound && progressInterval && phase > 0.9) {
        clearInterval(progressInterval);
        setProgressInterval(undefined);
    }

    const attackerRollRef = useRef<HTMLDivElement>(null);
    const defenderRollRef = useRef<HTMLDivElement>(null);

    // First of new roll
    if (phase < rollPerUpdate) {
        if (attackerRollRef.current) attackerRollRef.current.style.opacity = '0';
        if (defenderRollRef.current) defenderRollRef.current.style.opacity = '0';
    }

    if (phase > 0.1 && phase < 0.1 + rollPerUpdate) {
        anime({
            targets: attackerRollRef.current,
            opacity: 1,
            duration: 1/3 * SECONDS_PER_ROLL * 1000,
            easing: 'easeInOutQuad'
        });
    }

    if (phase > 0.43 && phase < 0.43 + rollPerUpdate) {
        anime({
            targets: defenderRollRef.current,
            opacity: 1,
            duration: 1/3 * SECONDS_PER_ROLL * 1000,
            easing: 'easeInOutQuad'
        });
    }

    return (
        <>
            <Title>Combat ({rollId})</Title>
            <Combatants>
                <H5>Attackers</H5>
                <Combatant num={attackersLeftBefore} color={COLOR.GREEN} lastWithX={phase > 0.8 ? attackersLeftBefore - attackersLeft : undefined} />
                <H5>Defenders</H5>
                <Combatant num={defendersLeftBefore} color={COLOR.RED} lastWithX={phase > 0.8 ? defendersLeftBefore - defendersLeft : undefined} />
            </Combatants>
            <H5 style={{textAlign: 'center'}}>Attackers</H5>
            <AttackerRoll ref={attackerRollRef}>
                { attackerRoll.map((roll, i) => (<Die value={roll} key={`att-${i}`}></Die>)) }
            </AttackerRoll>
            <DefenderRoll ref={defenderRollRef}>
                { defenderRoll.map((roll, i) => (<Die value={roll} key={`def-${i}`}></Die>)) }
            </DefenderRoll>
            <H5 style={{textAlign: 'center'}}>Defenders</H5>
            <CombatOutcome>

            </CombatOutcome>
            {progressInterval &&
            <Centerer>
                <Progress phase={phase} />
            </Centerer>}
            
        </>
    )
}

const Title = styled.h1`
    letter-spacing: 1px;
    text-align: center;
    margin-top: 0;
`

const Combatants = styled.div`
    border-bottom: 1px solid ${COLOR.DARK};
    padding-bottom: 4px;
`

const AttackerRoll = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 64px;
    align-items: center;
    opacity: 0;
    `

const DefenderRoll = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 64px;
    align-items: center;
    opacity: 0;
`
const CombatOutcome = styled.div`
    border-top: 1px solid ${COLOR.DARK};
`

const H5 = styled.h5`
    margin: 2px 0;
    text-transform: uppercase;
`

const CharacterIcon = ({ color }: { color?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: '32px', width: '32px'}}><path d="M0 0h512v512H0z" fillOpacity="0"></path><g transform="translate(0,0)"><path d="M255 45.4c-24.5 0-47 11.8-63.9 33.4-16.9 21.5-27.1 52.6-27.1 86.5 0 36 12.1 67.5 31 89.5l13.5 15-19.6 4.6c-52.3 11.9-77.4 36.9-91.75 75.2-13.7 35.7-15.6 84.8-16.1 143.3H431c-.2-58.7-.5-109.3-13-145.5-13.4-39.4-37.9-64.3-94-75.4l-19.9-3.7 12.9-15.7c17.7-21.9 28.8-52.6 28.8-87.5 0-33.9-10.3-64.9-27.2-86.3-16.8-21.7-39.3-33.6-63.6-33.4z" fill={color ?? COLOR.ACCENT} fillOpacity="1"></path></g></svg>
);

const Progress = ({ phase }: {phase: number}) => {
    const xP = Math.sin(2 * Math.PI * phase);
    const yP = Math.cos(2 * Math.PI * phase);
    const largeArc = phase > 0.5 ? 1 : 0;

    return (
    <svg width={32} height={32}>
        <path
            d={`M 16 0 v 16 l ${16 * xP} ${-16 * yP}
                A 16 16 0 ${largeArc} 0 16 0`}
            stroke={COLOR.ACCENT}
            fill={COLOR.DARK}
        />
    </svg>)
}

const Centerer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 16px;
`
